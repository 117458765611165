function showOnlyOnce(data, widgetType) {
  if (
    data.openOnce === true &&
    data.turnOffCookies !== true &&
    widgetType == 'classic'
  ) {
    if (document.cookie.indexOf('wWidgetOnlyOnce') == -1) {
      document.cookie = 'wWidgetOnlyOnce=true'
    } else {
      return false
    }
  } else if (widgetType == 'classic') {
    document.cookie =
      'wWidgetOnlyOnce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }
  return true
}

function getHue(rgbColor) {
  let r = 0,
    g = 0,
    b = 0
  if (rgbColor === undefined) return 0
  // 3 digits
  if (rgbColor.length == 4) {
    r = '0x' + rgbColor[1] + rgbColor[1]
    g = '0x' + rgbColor[2] + rgbColor[2]
    b = '0x' + rgbColor[3] + rgbColor[3]

    // 6 digits
  } else if (rgbColor.length == 7) {
    r = '0x' + rgbColor[1] + rgbColor[2]
    g = '0x' + rgbColor[3] + rgbColor[4]
    b = '0x' + rgbColor[5] + rgbColor[6]
  }
  if (Math.abs(r - g) <= 10 && Math.abs(g - b) <= 10 && Math.abs(b - r) <= 10)
    return -1
  r /= 255
  g /= 255
  b /= 255

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0
  // Calculate hue
  // No difference
  if (delta == 0) h = 0
  // Red is max
  else if (cmax == r) h = ((g - b) / delta) % 6
  // Green is max
  else if (cmax == g) h = (b - r) / delta + 2
  // Blue is max
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  // Make negative hues positive behind 360°
  if (h < 0) h += 360
  return h
}

function padValue(value, length = 2, char = '0') {
  if (value > 99) return 99
  const { length: currentLength } = value.toString()
  if (currentLength >= length) return value.toString()
  return `${char.repeat(length - currentLength)}${value}`
}

const conf = [
  {
    id: 'Return',
    name: 'Returns',
    color: '#F2DFC4',
    icon: 'https://widget.tochat.be/faq-icons/box.png',
  },
  {
    id: 'Broken',
    name: 'Broken',
    color: '#E8B5B0',
    icon: 'https://widget.tochat.be/faq-icons/broken-heart.png',
  },
  {
    id: 'Discount',
    name: 'Returns',
    color: '#D9DBC3',
    icon: 'https://widget.tochat.be/faq-icons/money-bag.png',
  },
  {
    id: 'Delivery',
    name: 'Returns',
    color: '#D7EEF6',
    icon: 'https://widget.tochat.be/faq-icons/broken-heart.png',
  },
]

const getConf = (id) => conf.find((o) => o.id == id)

const getproperty = (id, type) => {
  const obj = getConf(id)
  if (obj && obj.hasOwnProperty(type)) {
    return obj[type]
  } else {
    return ''
  }
}

const toArray = (data) => {
  return data.split('|')
}

const getWrittenName = (data, dataObject, showPrices, currency) => {
  if (!dataObject) {
    return data;
  }

  const price = dataObject.find(obj => obj?.value === data)?.price;
  const writtenName = dataObject.find(obj => (obj?.value === data) && (obj?.writtenName !== ''))?.writtenName;

  return showPrices ? `${writtenName ?? data} (${price} ${currency ?? 'USD'})` : (writtenName ?? data);
}

const splitNameAndPrice = (text) => {
  // regex to match the name and the price
  const regex = /(.*)\(([\d\sA-Z]+)\)/;
  const matches = text.match(regex);

  // if there is a match, return the name and the price
  if (matches && matches.length === 3) {
    return {
      name: matches[1].trim(), // Name
      price: matches[2].trim()  // Price
    };
  }

  // if there is no match, return the name and an empty price
  return {
    name: text,
    price: ''
  };
}

const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

const isIPhone = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}


const lightOrDark = (color) => {
  // Check the format of the color, HEX or RGB?
  let r, g, b
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 197.5) {
    return 'black'
  } else {
    return 'white'
  }
}

export { showOnlyOnce, getHue, padValue, getproperty, toArray, getWrittenName, splitNameAndPrice, isMobile, isIPhone, lightOrDark }
